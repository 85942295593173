<template>
  <div class="commodityevaluation">
    <van-nav-bar class="evaluation-title" title="商品评价" fixed left-arrow @click-left="onBack">
      <template #right @click-right="userKefu">
        <van-icon name="share-o" />
      </template>
    </van-nav-bar>

    <div class="shop_commodityevaluation">
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
      <div class="evaluation">
        <van-cell title="188****4233">
          <template #icon>
            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/icon-touxiang.png" size="2.6rem" />
          </template>
          <template #label>
            <van-tag color="#f5f5f5" text-color="#969799">2022-07-20 15:08:03 - 来自移动端</van-tag>
            <div class="evaluationContent">问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里问题的内容在这里显示，显示在这里</div>
          </template>
        </van-cell>
      </div>
    </div>

    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" @click="teacherShow = true" />
      <van-goods-action-icon icon="cart-o" text="购物车" info="9" @click="handelCart" />
      <van-goods-action-icon icon="like-o" text="收藏" @click="handelCollectAdd" />
      <van-goods-action-button type="warning" text="加入购物车" @click="handelCartAdd" />
      <van-goods-action-button type="danger" text="立即购买" @click="handelBuy" />
    </van-goods-action>


  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"



import { GetHomeList, } from '@/request/api'

export default {
  metaInfo: {
    title: '商品评价', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      //轮播图数组
      banner: [],
      current: 0,
      active: '',

    };
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    handelCart() {
      this.$router.push('/cart')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCollectAdd() {
      let status = true
      const p = document.getElementsByClassName('van-goods-action-icon')
      // const handelCollectAdd = () => {
      if (status) {
        status = false
        // console.log(p[2].childNodes[0])
        p[2].childNodes[0].classList.replace('van-icon-like-o', 'van-icon-like')
        p[2].childNodes[1].data = '已收藏'
        console.log(p[2])
      } else {
        status = true
        p[2].childNodes[0].classList.replace('van-icon-like', 'van-icon-like-o')
        p[2].childNodes[1].data = '收藏'
        console.log("进入收藏")
      }
      // }
    },
    handelCartAdd() {
      this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelBuy() {
      this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onBack() {
      history.back();
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    }
  },
  created() {

    // request.get("/index/index")
    GetHomeList()
      .then(res => {
        // console.log("success!!!!!111");
        // console.log(res);
        this.banner = res.data.data.banner
        // console.log(this.banner);
      })
      .catch(err => {
        console.log("error");
        console.log(err);
      })
  },
  components: {

  },

}
</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.commodityevaluation {
  background-color: #e7e9ed;
  // overflow: hidden;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }

.shop_commodityevaluation {
  background-color: #e7e9ed;
  padding: 6rem 0;
}

.shop_commodityevaluation .evaluation {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_commodityevaluation .evaluation .van-cell {
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.shop_commodityevaluation .evaluation .van-cell .van-icon {
  float: left;
  top: 0;
  margin: 0.3rem 0.6rem;
}

.shop_commodityevaluation .evaluation .van-cell .van-icon .van-icon__image {
  border-radius: 100%;
}

.shop_commodityevaluation .evaluation .van-cell .van-cell__title {
  color: #333;
  font-size: 1.8rem;
}

.shop_commodityevaluation .evaluation .van-cell .evaluationContent {
  color: #333;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.shop_commodityevaluation .evaluation .title-1 {
  height: 4rem !important;
}

.shop_commodityevaluation .evaluation .title-1 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 0.5rem;
}



.evaluation-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 3.2rem;
  padding: 0.5rem;
  color: #333333;
}

.evaluation-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  padding: 0 1rem;
  border-radius: 5rem;
  color: #333333;
}
</style>
